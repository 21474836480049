import React, { createContext, useState, useContext } from 'react';
import { ModalContextState } from 'types/context';

const IModalDefaultValue = {
  openModal: false,
  setOpenModal: (state: boolean) => {},
  index: 0,
  setIndex: (state: number) => {},
};

export const ModalContext = createContext<ModalContextState>(IModalDefaultValue);

export const ModalProvider: React.FC = ({ children }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const value = {
    openModal,
    setOpenModal,
    index,
    setIndex,
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useModalContext = () => useContext(ModalContext);
