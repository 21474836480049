import React from 'react';

import Compose from './src/libs/compose';

import { MenuProvider } from './src/hooks/menu-context';
import { ModalProvider } from './src/hooks/modal-context';

export const wrapRootElement = ({ element }) => {
  return <Compose components={[ModalProvider, MenuProvider]}>{element} </Compose>;
};
